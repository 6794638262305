import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const MARKETPLACE_LOCALES = {
	WOLTAIR_CZ: 'marketplace',
	WOLTAIR_PL: 'marketplace',
	WOLTAIR_DE: 'marketplace',
	WOLTAIR_IT: 'marketplace'
}

export const MARKETPLACE = MARKETPLACE_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return MARKETPLACE === param
}) satisfies ParamMatcher
